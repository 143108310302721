@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(../fonts/Poppins-Medium.ttf);
}

.modal-content .modal-body p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.modal-content .modal-footer {
    justify-content: center;
}

.modal-content .modal-title {
    width: 100%;
    text-align: center;
}
