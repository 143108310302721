@font-face {
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"), url(../fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url(../fonts/Roboto-Regular.ttf);
}


* {
    color: #333333;
    font-weight: 400;
    font-family: Roboto-Regular;
}

section {
    margin-top: 64px;
    position: relative;
    margin-bottom: 3rem;
    width: 100%;
}

.container-padre {
    display: inline-flex;
    justify-content: center;
}

.bloque {
    justify-content: center;
}
.bloque.desktop {
    display: flex;
}

.card {
    box-shadow: 0px 100px 58px rgba(31, 41, 35, 0.00458735),
        0px 41.346px 54.0995px rgba(31, 41, 35, 0.0109364),
        0px 19.4023px 49.8576px rgba(31, 41, 35, 0.0211526),
        0px 7.32773px 44.5609px rgba(31, 41, 35, 0.0458371),
        0px 1.03284px 36.9139px rgba(31, 41, 35, 0.0757482),
        0px -1.12437px 24.2747px rgba(31, 41, 35, 0.09);
    border-radius: 4px;
    width: 100%;
}

.card.formulario {
    max-width: 570px;
    justify-content: center;
    padding: 0.5rem 1rem;
}

.card .card-header {
    background-color: #ffffff !important;
}

.container-title {
    background-color: #72b500;
    width: 411px;
    height: 321px;
    padding: 1.5rem;
    position: relative;
}
.mobile .container-title {
    height: 270px;
}

.container-title h1 {
    color: #ffffff;
    font-size: 36px;
}

.container-title p {
    color: #ffffff;
    margin-top: 1rem;
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 16.8px;
}

.txtDesktop {
    margin: auto;
}

.bg-green {
    background-color: #80BA00 !important;
}
.bg-dark {
    background-color: #000000 !important;
}
.green {
    color: #72b500 !important;
}

.blue {
    color: #0b7ac7 !important;
}

.mobile {
    display: none !important;
}

/**********************Formulario**********************/
.form {
    margin-top: 3rem;
}

.form-control {
    display: block;
    line-height: 2em;
    margin: 0;
    padding-left: 10px;
    width: 100%;
    font-size: medium;
    border: 1px solid #f4f4f4;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
}

.form-control:focus {
    border: 1px solid #2c7ac9;
}

.control-label {
    display: block;
    position: absolute;
    opacity: 0;
    bottom: 1.9rem;
    color: #54575c;
    transition: 0.2s ease-in-out transform;
    font-size: 10px;
}


.form-group {
    margin: 12px;
}

.form-group input,
.form-group select {
    border: #999999 1px solid;
    border-radius: 4px;
    width: 100%;
}

.form-group small {
    margin: 0;
    font-size: 12px;
}

.select label {
    width: 100%;
    font-size: 14px;
}

.btn {
    background: #82388b;
    border-radius: 50px;
    color: #ffffff;
    width: 75%;
    font-family: Poppins-Regular;
    font-size: 1rem;
}

.btn:hover {
    color: #ffffff;
}

.terminosYcondiciones {
    font-size: 12px;
    color: #5b5b5b;
    line-height: 16px;
}

.form-check {
    margin: 1rem;
}
.medio {
    display: flex !important;
}
small {
    color: #6c757d;
}
small.is-invalid {
    color: #ed1c24;
}
small .is-valid {
    color: #1bb934;
}
input[type="checkbox"] + label {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
/**********************Fin formulario**********************/


.volver {
    margin-top: 1rem;
    color: #0B7AC7;
    font-size: 18px;
    line-height: 20px;
}

    .volver:hover {
        text-decoration: none;
    }

    .volver img {
        margin: 0 !important;
    }

@media (max-width: 62em) {
    .medio {
        display: none !important;
    }
    .form {
        margin-top: -2rem;
    }
}

@media (max-width: 48em) {
    .mobile {
        display: inline-flex !important;
        padding: 0;
    }

    .desktop {
        display: none !important;
    }

    .container-padre {
        display: block;
    }

    .container-title {
        padding: 2rem;
    }

    .bloque {
        display: flex;
    }

    .card {
        width: 100%;
    }

    section .row {
        margin: 0 !important;
    }

    .container-title h1 {
        font-size: 2rem;
    }
}

@media (max-width: 36em) {
    .container-title h1 {
        font-size: 2rem;
        line-height: 1.9rem;
    }
    body {
        width: 100% !important;
    }
    .form {
        margin-top: calc(10px - 12%);
        padding: 0 7%;
    }
    .mobile .container_reasons img {        
        left: 255px;
        top: 34%;
        width: 150px;
    }
    

}

@media (max-width: 23.4em){
    .label-mobile-error {
        transform: translate(5px, -25px) !important;
    }
}
.align {
	margin-top: 8px;
	margin-left: 96px;
	margin-bottom: 8px;
}
.logo {
    background-image: url("../img/logo_positivo.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    left: 56px;
    width: 134px;
    height: 92px;
}
.bg-header-c {
    background-color: #000000 !important;
    width: 100%;
    height: 80px;
}

