@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url(../fonts/Roboto-Regular.ttf);
}

.desktop .container-title {
    background-color: transparent;
}

.desktop .container-title h1 {
    color: #333333;
    font-size: 40px;
    font-family: Roboto-Regular;
    font-style: normal;
    line-height: 130%;
    font-weight: 500;
}

.desktop .container-title h1.blue {
    color: #0b7ac7;
}

.desktop .container-title p {
    color: #54575c;
    text-align: left;
}

.bloque.desktop img {
    position: relative;
    top: 15%;
    /*width: 118%;*/
}

.input-wrapper {
    position: relative;
    margin: 20px auto;
}
.mt-15{
    margin-top: 15%;
}
.container {
    background-color: #0b7ac7;
}

.container h1 {
    color: #ffffff;
}

    .btn.disabled, .btn:disabled {
        background: #8F9196 !important;
        opacity: 1 !important;
    }

.background {
    background-image: url("../svg/background.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0rem) calc(100% - 5rem);
    background-size: 700px;
    margin-top:-64px;
}
.container_reasons {
    align-items: center;
}
.desktop {
    font-family: Roboto-Regular;
    display: inherit;
}
.terminosycondiciones a {
    color: #0b7ac7 !important;
    cursor: pointer;
}

@media (max-width: 768px) {
    .background {
        background-image: none;
        width: 100%;
    }

    .calculadora .container-title {
        width: 100%;
        padding: 3rem;
        padding-bottom: 5rem;
    }

    .calculadora .container-title img {
        position: absolute;
        left: 300px;
        top: 43%;
        width: 50px;
    }

    .mobile .container-title {
        font-family: Roboto-Regular;
        position: relative;
    }
    .mobile .container-title p {        
        text-align: left;
        padding-right: 45%;
    }
    .mobile .container-title h4 {
        color:#ffffff;        
    }
    .mobile .container-title strong {
        color:#ffffff;        
    }
}

@media (max-width: 576px) {
    .calculadora .container-title {
        padding: 1.5rem;
    }
    .calculadora .container-title img {
        left: 50%;
        top: 40%;
        width: 150px;
    } 
    .mobile .container-title {
        position: relative;
    }
    .mobile .container-title h4 {
        color:#ffffff;        
    }
    .mobile .container-title p {
        text-align: left;
        padding-right: 45%;        
    }
    .mobile .container-title strong {
        color:#ffffff;        
    }
    
    
}

@media only screen and (min-width: 411px) and (-webkit-device-pixel-ratio: 3) {
    .label-sueldo {
        transform: translate(5px, -25px) !important;
    }
}

.color-change-3x {
    -webkit-animation: color-change-3x 4s linear infinite alternate both;
    animation: color-change-3x 4s linear infinite alternate both;
}

@-webkit-keyframes color-change-3x {
    0% {
        background: #0b7ac7;
    }

    50% {
        background: #82388b;
    }

    100% {
        background: #72b500;
    }
}

@keyframes color-change-3x {
    0% {
        background: #0b7ac7;
    }

    50% {
        background: #82388b;
    }

    100% {
        background: #72b500;
    }
}

.couple-image{
    height: 500px;
    margin-top: 25%;
}