.footer * {
    font-size: 10px;
    color: #ffffff;
}
.disclaimer {
    font-size: 10px;
}

.footer p {
    padding: 1.5rem;
    margin: 0;
}

.footer {
    background: #0c4c72;
    position: relative;
    bottom: 0;
}
